import { FC, useCallback, useRef } from 'react'
import styles from './CountUp.module.scss'
import { CountUpProps } from './CountUp.types'
import { clamp, lerp } from 'components/_utils/mathUtils'
import cx from 'classnames'
import { useAnimation } from 'components/_hooks/useAnimation'

/**
 * The maximum number of slots in the wheel animation.
 *
 * We use a fixed number of slots as a "render pool", so that we can reuse
 * the same elements for different values. This allows the component to support
 * extremely large numbers without a performance hit.
 */
const MAX_SLOTS = 10

/**
 * A component that animates a number from a start value to an end value.
 * The component supports both counting up and counting down,
 * with two different styles: `in-place` and `wheel`.
 * The component also has sensible defaults,
 * so all you need to do is provide the `endValue` prop.
 *
 * The component requires a `trigger` prop to start the animation.
 * This is to prevent the animation from starting when the component mounts,
 * so you'll need to handle the trigger state in your parent component.
 */
export const CountUp: FC<CountUpProps> = (props) => {
	const { startValue = 0, endValue, animationStyle = 'wheel', hideItemsOutsideRange = true } = props
	const { ease, trigger, duration, repeat, onTimelineReady } = props
	const { className } = props

	const wheelBaseValuesRef = useRef<number[]>(Array.from({ length: MAX_SLOTS }).map((_, i) => i + startValue - MAX_SLOTS / 2))
	const wheelAdjustmentsRef = useRef<number[]>(Array.from({ length: MAX_SLOTS }).map((_) => 0))
	const wheelItemsRef = useRef<HTMLSpanElement[]>([])

	const inPlaceRef = useRef<HTMLSpanElement>(null)

	const localeFormatterRef = useRef(new Intl.NumberFormat())

	const update = useCallback(
		(t: number) => {
			let value = lerp(startValue, endValue, t)
			const localeFormatter = localeFormatterRef.current

			if (animationStyle == 'in-place') {
				if (hideItemsOutsideRange) {
					const minValue = Math.min(startValue, endValue)
					const maxValue = Math.max(startValue, endValue)
					value = clamp(value, minValue, maxValue)
				}
				updateInPlace(inPlaceRef.current, localeFormatter.format(Math.round(value)))
			}

			if (animationStyle == 'wheel') {
				const wheelBaseValues = wheelBaseValuesRef.current
				const wheelAdjustments = wheelAdjustmentsRef.current
				const wheelItems = wheelItemsRef.current
				updateWheel(value, { wheelBaseValues, wheelAdjustments, wheelItems }, localeFormatter)
				if (hideItemsOutsideRange) {
					updateItemsOutsideRange({ wheelBaseValues, wheelAdjustments, wheelItems }, startValue, endValue)
				}
			}
		},
		[animationStyle, hideItemsOutsideRange, startValue, endValue]
	)

	const handleTimelineReady = (timeline: gsap.core.Timeline) => {
		onTimelineReady?.(timeline)
	}

	useAnimation(update, { duration, ease, repeat, trigger }, handleTimelineReady)

	return (
		<span className={cx(styles.container, className)}>
			{animationStyle === 'in-place' && (
				<span
					aria-hidden={true}
					ref={inPlaceRef}
					className={styles.float}
				>
					{localeFormatterRef.current.format(startValue)}
				</span>
			)}

			{animationStyle === 'wheel' && (
				<span
					className={styles.wheel}
					aria-hidden={true}
				>
					{Array.from({ length: MAX_SLOTS }).map((_, i) => {
						const value = i + startValue - MAX_SLOTS / 2
						return (
							<span
								key={i}
								className={styles.slot}
								ref={(el) => {
									wheelItemsRef.current[i] = el!
								}}
								style={{
									transform: `translateY(${(i - MAX_SLOTS / 2) * 100}%)`,
								}}
								aria-hidden={true}
							>
								{localeFormatterRef.current.format(value)}
							</span>
						)
					})}
				</span>
			)}

			{/* The final anchor serves two purposes: 
			it provides a screen-reader accessible value for the final value,
			and it ensures that the spacing of the text doesn't jitter during the animation */}
			<span className={styles.final_anchor}>{localeFormatterRef.current.format(endValue)}</span>
		</span>
	)
}

const updateInPlace = (span: HTMLSpanElement, value: string) => {
	if (!span) return
	// We only have one element in the in-place style, so we can just update the text content
	span.textContent = value
}

interface WheelData {
	wheelBaseValues: number[]
	wheelAdjustments: number[]
	wheelItems: HTMLSpanElement[]
}

const updateWheel = (value: number, wheelData: WheelData, localeFormatter: Intl.NumberFormat) => {
	const { wheelBaseValues, wheelAdjustments, wheelItems } = wheelData
	for (let i = 0; i < wheelItems.length; i++) {
		const item = wheelItems[i]
		if (!item) continue
		let itemValue = wheelBaseValues[i] + wheelAdjustments[i]

		const diff = Math.abs(value - itemValue)
		// If a slot is at least MAX_SLOTS / 2 away from the current value, we can safely swap it back down to the bottom with a new value
		if (diff > MAX_SLOTS / 2) {
			wheelAdjustments[i] += Math.sign(value - itemValue) * MAX_SLOTS
			itemValue = wheelBaseValues[i] + wheelAdjustments[i]
			item.textContent = localeFormatter.format(itemValue)
		}

		if (item) {
			item.style.transform = `translateY(${(itemValue - value) * 100}%)`
		}
	}
}

const updateItemsOutsideRange = (wheelData: WheelData, startValue: number, endValue: number) => {
	const { wheelBaseValues, wheelAdjustments, wheelItems } = wheelData
	for (let i = 0; i < wheelItems.length; i++) {
		const item = wheelItems[i]
		if (!item) continue
		const itemValue = wheelBaseValues[i] + wheelAdjustments[i]

		const startDiff = startValue - itemValue
		const endDiff = endValue - itemValue

		if (Math.sign(startDiff) === Math.sign(endDiff)) {
			item.style.opacity = '0'
		} else {
			item.style.opacity = '1'
		}
	}
}
