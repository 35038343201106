/**
 * Width of the SVG element
 */
const SVG_WIDTH = 164

/**
 * Height of the SVG element
 */
const SVG_HEIGHT = 164

/**
 * X coordinate of the center of the progress arc
 */
const CIRCLE_X = SVG_WIDTH / 2

/**
 * Y coordinate of the center of the progress arc
 */
const CIRCLE_Y = SVG_HEIGHT / 2

/**
 * Radius of the progress arc
 */
const CIRCLE_R = 76

/**
 * Width of the progress arc
 */
const CIRCLE_STROKE_WIDTH = 3

/**
 * Fraction of the progress arc to fill
 */
const BASE_CIRCLE_SWEEP = 0.75

/**
 * Rotation of the progress arc, so it spills evenly over the top
 */
const BASE_ROTATION = 270 - (360 * BASE_CIRCLE_SWEEP) / 2

/**
 * Circumference of the progress arc
 */
const CIRCUMFERENCE = Math.PI * 2 * CIRCLE_R

/**
 * Dash array for the progress arc
 */
const BASE_DASH_ARRAY = `${CIRCUMFERENCE * BASE_CIRCLE_SWEEP} ${CIRCUMFERENCE * (1 - BASE_CIRCLE_SWEEP)}`

/**
 * Maximum temperature for the glass in Fahrenheit. The actual maximum temperature is 110.
 */
const MAX_TEMP = 85

/**
 * Minimum temperature for the glass in Fahrenheit. The actual minimum temperature is 55.
 */
const MIN_TEMP = 55

export default {
	SVG_WIDTH,
	SVG_HEIGHT,
	CIRCLE_X,
	CIRCLE_Y,
	CIRCLE_R,
	CIRCLE_STROKE_WIDTH,
	BASE_CIRCLE_SWEEP,
	BASE_ROTATION,
	CIRCUMFERENCE,
	BASE_DASH_ARRAY,
	MAX_TEMP,
	MIN_TEMP,
}
