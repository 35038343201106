import { forwardRef, useCallback, useEffect, useId, useState } from 'react'
import styles from './TempDial.module.scss'
import { TempDialProps, TemperatureType } from './TempDial.types'
import cx from 'classnames'
import { getTemperatureNumber, getTemperatureUnit } from 'components/_utils/temperatureUtils'
import { lerp, map } from 'components/_utils/mathUtils'
import { CountUp } from 'components/CountUp'
import { gsap } from 'gsap'
import tempDialConstants from './TempDial.constants'
import { useAnimation } from 'components/_hooks/useAnimation'
import { getStartingTempType, updateSVG } from './TempDial.utils'
import { TempDialReading } from './subcomponents/TempDialReading'

const { MIN_TEMP, MAX_TEMP } = tempDialConstants

/**
 * The temp dial component animates a glassmorphic temperature dial from
 * a given starting temperature to an ending temperature. You can pass
 * a ref to grab the top level container if you want to animate the component in.
 */
export const TempDial = forwardRef<HTMLDivElement, TempDialProps>((props, ref) => {
	const { className, metric, text } = props
	const { startTemp = (MAX_TEMP + MIN_TEMP) / 2, endTemp, forceType } = props
	const { duration, ease, repeat, trigger, onTimelineReady } = props

	const _type = forceType || getStartingTempType(startTemp, endTemp)
	const [type, setType] = useState<TemperatureType>(_type)
	const [countUpTimeline, setCountUpTimeline] = useState<gsap.core.Timeline | null>(null)

	const tipID = useId()
	const maskID = useId()

	useEffect(() => {
		if (forceType) {
			setType(forceType)
		}
	}, [forceType])

	const handleTimelineReady = (timeline: gsap.core.Timeline) => {
		onTimelineReady?.(timeline)
	}

	const handleCountUpTimelineReady = (timeline: gsap.core.Timeline) => {
		setCountUpTimeline(timeline)
	}

	const update = useCallback(
		(t: number) => {
			const temp = lerp(startTemp, endTemp, t)
			const temperatureReading = map(temp, MIN_TEMP, MAX_TEMP, -1, 1)

			updateSVG(temperatureReading, maskID, tipID)

			// Epsilon to prevent flickering close to 0
			if (Math.abs(temperatureReading) > 0.01 && !forceType) {
				if (temperatureReading < 0) {
					setType('cold')
				} else {
					setType('warm')
				}
			}

			countUpTimeline?.progress(t)
		},
		[startTemp, endTemp, maskID, tipID, countUpTimeline, forceType]
	)

	useAnimation(update, { duration, ease, repeat, trigger }, handleTimelineReady)

	return (
		<div
			className={cx(styles.glass, className)}
			ref={ref}
		>
			<div
				className={styles.warm_pane}
				style={{
					opacity: type === 'warm' ? 1 : 0,
				}}
				aria-hidden={true}
			/>

			<div
				className={styles.cool_pane}
				style={{
					opacity: type === 'cold' ? 1 : 0,
				}}
				aria-hidden={true}
			/>

			<TempDialReading
				type={type}
				maskID={maskID}
				tipID={tipID}
			/>

			<p
				className={styles.temperature}
				style={{
					color: type === 'warm' ? '#e86b6b' : '#5e69ff',
				}}
			>
				<CountUp
					startValue={Math.round(getTemperatureNumber(startTemp, metric))}
					endValue={Math.round(getTemperatureNumber(endTemp, metric))}
					ease={ease}
					repeat={repeat}
					duration={duration}
					animationStyle={'in-place'}
					onTimelineReady={handleCountUpTimelineReady}
				/>
				<span className={styles.degrees}>{getTemperatureUnit(metric)}</span>
			</p>

			<p className={styles.side}>{text}</p>
		</div>
	)
})
