import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { EaseString } from 'components/_types/animationTypes'

export interface AnimationOptions {
	/**
	 * The duration of the animation in seconds.
	 *
	 * Defaults to 4.
	 */
	duration: number

	/**
	 * If `true`, the animation will repeat indefinitely.
	 *
	 * Defaults to `false`.
	 */
	repeat: boolean

	/**
	 * The easing function to use for the animation.
	 * This can be expanded in the future to support custom easing functions
	 * (gsap can use a simple `(t: number) => number` function).
	 *
	 * Defaults to `power1.out`.
	 *
	 * @see https://greensock.com/docs/v3/Eases
	 */
	ease: EaseString

	/**
	 * If `trigger` is `true`, the animation will start when the component mounts.
	 * If `false`, the animation will start when the `trigger` prop is set to true.
	 *
	 * Defaults to `false`.
	 */
	trigger: boolean

	/**
	 * A function that is called when the timeline is ready.
	 * Typically used to bring the timeline up to the parent component.
	 */
	onTimelineReady?: (timeline: gsap.core.Timeline) => void
}

/**
 * A hook that creates a GSAP timeline to control an abstract update function.
 * @param update The function that will be called with a `t` value from 0 to 1.
 * @param options The options for the animation.
 * @param onTimelineReady An optional callback that will be called with the timeline when it's ready.
 */
export const useAnimation = (update: (t: number) => void, options: AnimationOptions, onTimelineReady?: (timeline: gsap.core.Timeline) => void) => {
	const { duration = 4, repeat = false, ease = 'power1.out', trigger = false } = options
	const timelineRef = useRef<gsap.core.Timeline | null>(null)
	const targetRef = useRef({ t: 0 })
	const updateFunctionRef = useRef(update)

	useGSAP(
		() => {
			timelineRef.current = gsap.timeline({
				repeat: repeat ? -1 : 0, // -1 means repeat indefinitely
				paused: !trigger,
			})

			timelineRef.current.to(targetRef.current, {
				t: 1,
				duration: duration,
				ease: ease,
				onUpdate: () => {
					updateFunctionRef.current(targetRef.current.t)
				},
			})

			if (onTimelineReady) {
				onTimelineReady(timelineRef.current)
			}
		},
		{
			dependencies: [duration, repeat, ease],
			revertOnUpdate: true, // Revert the timeline if the dependencies change
		}
	)

	useEffect(() => {
		updateFunctionRef.current = update
	}, [update])

	useEffect(() => {
		if (trigger) {
			timelineRef.current?.play()
		} else {
			timelineRef.current?.pause()
		}
	}, [trigger])

	return timelineRef.current
}
