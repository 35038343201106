import { TemperatureType } from 'components/Phantom/TempDial/TempDial.types'
import { useId } from 'react'
import tempDialConstants from 'components/Phantom/TempDial/TempDial.constants'

const { CIRCLE_X, CIRCLE_Y, CIRCLE_R, CIRCLE_STROKE_WIDTH, CIRCUMFERENCE, BASE_DASH_ARRAY, BASE_ROTATION, SVG_WIDTH, SVG_HEIGHT } = tempDialConstants

export const TempDialReading = (props: { type: TemperatureType; tipID: string; maskID: string }) => {
	const gradientID = useId()
	const clipID = useId()

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
			fill="none"
			aria-hidden={true}
		>
			<circle
				cx={CIRCLE_X}
				cy={CIRCLE_Y}
				r={CIRCLE_R}
				fill="none"
				stroke={'white'}
				strokeWidth={CIRCLE_STROKE_WIDTH}
				strokeLinecap={'round'}
				strokeDasharray={BASE_DASH_ARRAY}
				transform={`rotate(${BASE_ROTATION} ${CIRCLE_X} ${CIRCLE_Y})`}
				opacity={0.15}
			/>

			<mask id={clipID}>
				<circle
					cx={CIRCLE_X}
					cy={CIRCLE_Y}
					r={CIRCLE_R}
					fill="none"
					stroke={'white'}
					strokeWidth={CIRCLE_STROKE_WIDTH}
					strokeDasharray={`0 ${CIRCUMFERENCE}`}
					transform={`rotate(${270} ${CIRCLE_X} ${CIRCLE_Y})`}
					strokeLinecap={'round'}
					id={props.maskID}
				/>
			</mask>

			<rect
				cx={0}
				cy={0}
				width={SVG_WIDTH}
				height={SVG_HEIGHT}
				fill={`url(#${gradientID})`}
				mask={`url(#${clipID})`}
			/>

			<circle
				cx={CIRCLE_X}
				cy={CIRCLE_Y - CIRCLE_R}
				r={CIRCLE_STROKE_WIDTH}
				fill="white"
				stroke={props.type === 'warm' ? '#463144' : '#313246'}
			/>

			<circle
				r={CIRCLE_STROKE_WIDTH * 2}
				fill="white"
				id={props.tipID}
				cx={CIRCLE_X}
				cy={CIRCLE_Y - CIRCLE_R}
			/>

			<defs>
				<linearGradient
					id={gradientID}
					x1="80"
					y1="0"
					x2="0"
					y2="100"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={props.type === 'warm' ? '#FF7777' : '#3453BF'} />
					<stop
						offset="1"
						stopColor={props.type === 'warm' ? '#BF3434' : '#778DFF'}
					/>
				</linearGradient>
			</defs>
		</svg>
	)
}
